<template>
    <div class="internal-sections" v-loading="loadingData">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Xodimlar</div>
                        <div class="filter-internal-block">
                            <el-input
                                prefix-icon="el-icon-search"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-breadcrumb class="mt10">
                <el-breadcrumb-item :to="{ path: '/doctorTable' }">
                    Vrachlar
                </el-breadcrumb-item>
                <el-breadcrumb-item> {{model.clinic ? model.clinic.legal_entity_name : ''}} </el-breadcrumb-item>
                <el-breadcrumb-item>  {{model.surname ? model.surname : '' }} {{ model.name ? model.name  : '' }} 
                                   {{ model.lastname ? model.lastname : ''}} </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-row type="flex" class="row-bg" justify="center">
            <el-col :span="24">
                <div class="doctorCardInfo">
                    <div class="card item-doctor">
                        <div class="top-info-doctor">
                            <div class="doctor-img-i-o">
                                <div class="item-doctor-img">
                                    <el-image 
                                        class="img-avatar"
                                        v-if="model.image"
                                        :src="appURL + '/' + model.image.path" 
                                        :preview-src-list="[appURL + '/' + model.image.path]">
                                    </el-image>
                                    <img
                                        v-else
                                        class="img-avatar"
                                        src="./../../../public/img/avatar_doctor.png"
                                    /> 
                                    <!-- <img
                                        v-if="model.image"
                                        class="img-avatar"
                                        :src="
                                            appURL + '/' + model.image.path
                                        "
                                    /> --> 
                                </div>
                            </div>
                            <div class="doctor-mini-i-o">
                                <span class="job">{{ model.typesOfEmployee ? model.typesOfEmployee.name : '---' }}</span>
                                <h4 class="title">
                                   {{model.surname ? model.surname : '' }} {{ model.name ? model.name  : '' }} 
                                   {{ model.lastname ? model.lastname : ''}}
                                </h4>
                                <!-- <p>
                                    <i class="el-icon-star-off"></i>
                                    Tibbiyot fanlar nomzodi
                                </p> -->
                                <!-- <p>
                                    <i class="el-icon-suitcase-1"></i>
                                    Tajriba: 15 yil
                                </p> -->
                                <p>
                                    <i class="el-icon-office-building"></i>
                                   {{ model.clinic ? model.clinic.legal_entity_name : '---'}}
                                </p>

                                <p>
                                    <i class="el-icon-location-outline"></i>
                                    {{model.region ? model.region.region_name : ''}} {{model.city_district ? model.city_district.name : ''}}
                                    {{model.neighborhood ? model.neighborhood : '' + ' ' + model.home ? model.home : '' + ' ' 
                                    + model.apartment_number ? model.apartment_number : ''}}
                                </p>
                            </div>
                        </div>

                        <el-tabs type="border-card">
                            <el-tab-pane label="Shaxsiy ma'lumotlar">
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Pasport seriyasi va raqami
                                    </h5>
                                    <p> {{model.passport_series_and_number ? model.passport_series_and_number : ''}}
                                        {{model.id_number ? model.id_number : ''}}
                                    </p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Tug'ilgan sana</h5>
                                    <p>{{ model.brith_date ? model.brith_date : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">STIR</h5>
                                    <p>{{ model.stir ? model.stir : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">INN</h5>
                                    <p>{{ model.inn ? model.inn : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Elektron pochta manzili
                                    </h5>
                                    <p>{{ model.email ? model.email : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Ish Telefoni raqami</h5>
                                    <p>{{ model.work_tel_number ? model.work_tel_number : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Mobil Telefoni raqami</h5>
                                    <p>{{ model.mobile_tel_number ? model.mobile_tel_number : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Jinsi</h5>
                                    <p>{{ model.gender ? model.gender : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Millati</h5>
                                    <p>{{ model.nation ? model.nation : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Tug'ilgan joyi</h5>
                                    <p>{{ model.address ? model.address : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title"></h5>
                                    <p></p>
                                </div>
                            </el-tab-pane>
                            <!-- <el-tab-pane label="Hujjatlar">
                                <div class="fayil-full">
                                    <div class="fayil-itme">
                                        <div class="itme-disct-doc">
                                            <div class="itme-disct-left">
                                                <a
                                                    href="./../../../public/img/file.png"
                                                    download
                                                >
                                                    <i
                                                        class="el-icon-download"
                                                    ></i>
                                                </a>
                                                <i class="el-icon-view"></i>
                                                <i><b>25MG</b></i>
                                            </div>
                                            <div class="itme-disct-right">
                                                <img
                                                    src="./../../../public/img/file.png"
                                                />
                                            </div>
                                        </div>
                                        <div class="name-distc">
                                            Fayil: <b>Test</b>
                                        </div>
                                    </div>

                                    <div class="fayil-itme">
                                        <div class="itme-disct-doc">
                                            <div class="itme-disct-left">
                                                <i class="el-icon-download"></i>
                                                <i class="el-icon-view"></i>
                                                <i><b>25MG</b></i>
                                            </div>
                                            <div class="itme-disct-right">
                                                <img
                                                    src="./../../../public/img/file.png"
                                                />
                                            </div>
                                        </div>
                                        <div class="name-distc">
                                            Fayil: <b>Test</b>
                                        </div>
                                    </div>

                                    <div class="fayil-itme">
                                        <div class="itme-disct-doc">
                                            <div class="itme-disct-left">
                                                <i class="el-icon-download"></i>
                                                <i class="el-icon-view"></i>
                                                <i><b>25MG</b></i>
                                            </div>
                                            <div class="itme-disct-right">
                                                <img
                                                    src="./../../../public/img/file.png"
                                                />
                                            </div>
                                        </div>
                                        <div class="name-distc">
                                            Fayil: <b>Test</b>
                                        </div>
                                    </div>

                                    <div class="fayil-itme">
                                        <div class="itme-disct-doc">
                                            <div class="itme-disct-left">
                                                <i class="el-icon-download"></i>
                                                <i class="el-icon-view"></i>
                                                <i><b>25MG</b></i>
                                            </div>
                                            <div class="itme-disct-right">
                                                <img
                                                    src="./../../../public/img/file.png"
                                                />
                                            </div>
                                        </div>
                                        <div class="name-distc">
                                            Fayil: <b>Test</b>
                                        </div>
                                    </div>

                                    <div class="fayil-itme">
                                        <div class="itme-disct-doc">
                                            <div class="itme-disct-left">
                                                <i class="el-icon-download"></i>
                                                <i class="el-icon-view"></i>
                                                <i><b>25MG</b></i>
                                            </div>
                                            <div class="itme-disct-right">
                                                <img
                                                    src="./../../../public/img/file.png"
                                                />
                                            </div>
                                        </div>
                                        <div class="name-distc">
                                            Fayil: <b>Test</b>
                                        </div>
                                    </div>

                                    <div class="fayil-itme">
                                        <div class="itme-disct-doc">
                                            <div class="itme-disct-left">
                                                <i class="el-icon-download"></i>
                                                <i class="el-icon-view"></i>
                                                <i><b>25MG</b></i>
                                            </div>
                                            <div class="itme-disct-right">
                                                <img
                                                    src="./../../../public/img/file.png"
                                                />
                                            </div>
                                        </div>
                                        <div class="name-distc">
                                            Fayil: <b>Test</b>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane> -->
                            <el-tab-pane label="Mutaxassisliklar">
                                <h5 class="title">Образование</h5>
                                <ul class="list-bullet" v-for="(information, index) in informations" :key="'information-'+index">
                                    <li>

                                        {{information.when_it_started ? information.when_it_started : ''}} г. — 
                                        {{information.higher_education_institution ? information.higher_education_institution.name : ''}},
                                        специальность {{information.direction ? information.direction.name : ''}}.
                                    </li>
                                    <li>
                                        <span>Diplom raqami: </span> {{ information.diploma_serial_number ? information.diploma_serial_number : '' }}
                                    </li>
                                    <li>
                                        <span>Ilova raqami: </span> {{ information.application_serial_number ? information.application_serial_number : '' }}
                                    </li>
                                        <div class="fayil-full">
                                            <div class="fayil-itme" v-for="(file, index) in information.files" :key="'file-'+ index">
                                                <div class="itme-disct-doc" v-for="(item, index) in file.file_items" :key="'item-'+index">
                                                    <div class="itme-disct-left"> 
                                                        <a
                                                            href="#"
                                                            @click="downloadFile(item)"
                                                        >
                                                            <i
                                                                class="el-icon-download"
                                                            ></i>
                                                        </a>
                                                        <i class="el-icon-view"></i>
                                                        <!-- <i><b>25MB</b></i> -->
                                                    </div>
                                                    <div class="itme-disct-right">
                                                        <img
                                                            :src="appURL + item.path"
                                                        />
                                                    </div>
                                                    <div class="name-distc">
                                                    Fayil: <b>{{ item.original_name }}</b>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                </ul>
                            </el-tab-pane>
                            <el-tab-pane label="Ish Tajribasi">
                                <div v-for="(work, index) in work_experiences" :key="'work-'+index">
                                <div class="itme-infom">
                                    <h5 class="title">Ish Tajribasi</h5>
                                    <p>{{ work.work_experience ? 'Ha' : (!work.work_experience ? "Yo'q" : '') }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Qachon tugatgan</h5>
                                    <p>{{work.when_finished ? work.when_finished : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Necha yil</h5>
                                    <p>{{work.how_many_years ? work.how_many_years : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Ish Malakasi 5 yildan oshganmi?
                                        (Ixtisosligi Malaka toyifasi bormi)
                                    </h5>
                                    <p>{{ work.work_experience ? 'Ha' : (!work.work_experience ? "Yo'q" : '') }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Malaka Toifasi</h5>
                                    <p>{{work.qualification_category ? work.qualification_category.category : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Qaysi Ixtisosligi boyicha malaka
                                        toyifasi olgan
                                    </h5>
                                    <p>{{work.direction ? work.direction.name : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Malaka toifasi qachon olgan
                                    </h5>
                                    <p>{{ work.when_received ? work.when_received : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Malaka toifasi qanday xujalar asosida
                                        berilgan
                                    </h5>
                                    <p>{{ work.what_documents ? work.what_documents : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Hujjat seriyasi raqami
                                    </h5>
                                    <p>{{ work.document_serial_number ? work.document_serial_number : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Hujjat Sanasi</h5>
                                    <p>{{ work.document_date ? work.document_date : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Hujjat Bergan tashkilot nomi
                                    </h5>
                                    <p>{{ work.name_organization ? work.name_organization : '' }}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Hujjat imzolagan shaxs FISH
                                    </h5>
                                    <p>{{ work.person_who_signed ? work.person_who_signed : '' }}</p>
                                </div>

                                <div class="fayil-full">
                                    <div class="fayil-itme" v-for="(work_file, index) in work.files" :key="'work_file-'+index">
                                        <div class="itme-disct-doc" v-for="(work_item, index) in work_file.file_items" :key="'work_file-'+index">
                                            <div class="itme-disct-left">
                                                <a
                                                    href="#"
                                                    @click="downloadFile(work_item)"
                                                >
                                                    <i
                                                        class="el-icon-download"
                                                    ></i>
                                                </a>
                                                <i class="el-icon-view"></i>
                                            </div>
                                            <div class="itme-disct-right">
                                                <img
                                                    :src="appURL + work_item.path"
                                                />
                                            </div>
                                            <div class="name-distc">
                                                Fayil: <b>{{ work_item.original_name }}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Sertifikarlar">
                                <div v-for="(certificate, index) in certificates" :key="'certificates-'+index">
                                     <div class="itme-infom">
                                    <h5 class="title">Sertifikatlar Nomi</h5>
                                    <p>{{certificate.name ? certificate.name : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Ixtisosligi</h5>
                                    <p>{{certificate.direction ? certificate.direction.name : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Malaka toifasi qachon olgan
                                    </h5>
                                    <p>{{certificate.when_received ? certificate.when_received : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Sertifikarlar qanday xujalar asosida
                                        berilgan
                                    </h5>
                                    <p>{{certificate.what_documents ? certificate.what_documents : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Sertifikarlar seriyasi raqami
                                    </h5>
                                    <p>{{certificate.certificate_serial_number ? certificate.certificate_serial_number : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">Sertifikarlar Sanasi</h5>
                                    <p>{{certificate.certificate_date ? certificate.certificate_date : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Sertifikarlar Bergan tashkilot nomi
                                    </h5>
                                    <p>{{certificate.name_organization ? certificate.name_organization : ''}}</p>
                                </div>
                                <div class="itme-infom">
                                    <h5 class="title">
                                        Sertifikarlar imzolagan shaxs FISH
                                    </h5>
                                    <p>{{certificate.person_who_signed ? certificate.person_who_signed : ''}}</p>
                                </div>

                                <div class="fayil-full">
                                    <div class="fayil-itme" v-for="(file, index) in certificate.files" :key="'certificate_file-'+index">
                                        <div class="itme-disct-doc" v-for="(item, index) in file.file_items" :key="'certificate_item' + index">
                                            <div class="itme-disct-left">
                                                <a
                                                    href="#"
                                                    @click="downloadFile(item)"
                                                >
                                                    <i
                                                        class="el-icon-download"
                                                    ></i>
                                                </a>
                                                <i class="el-icon-view"></i>
                                            </div>
                                            <div class="itme-disct-right">
                                                <img
                                                    :src="appURL + item.path"
                                                />
                                            </div>
                                            <div class="name-distc">
                                                Fayil: <b>{{ item.original_name }}</b>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Ilmiy Daraja">
                                <div v-for="(degree, index) in academic_degrees" :key="'degree-'+index">
                                    <div class="itme-infom">
                                    <h5 class="title">Ilmiy daraja bormi</h5>
                                    <p>{{degree.academic_degree ? degree.academic_degree.name : ''}}</p>
                                    </div>

                                    <div class="itme-infom">
                                        <h5 class="title">
                                            Ilmiy Daraja qayerda va qanday olingan
                                        </h5>
                                        <p>{{degree.where_taken ? degree.where_taken : ''}}</p>
                                    </div>
                                    <div class="itme-infom">
                                        <h5 class="title">
                                            Ilmiy Daraja qanday hujatlar bilan
                                            imzolangan
                                        </h5>
                                        <p>{{degree.documents_signed ? degree.documents_signed : ''}}</p>
                                    </div>
                                    <div class="itme-infom">
                                        <h5 class="title">
                                            Ilmiy Daraja qanday hujatlar bilan
                                            imzolangan seriyasi raqami
                                        </h5>
                                        <p>{{ degree.academic_degree_serial_number ? degree.academic_degree_serial_number : '' }}</p>
                                    </div>

                                    <div class="fayil-full">
                                        <div class="fayil-itme" v-for="(file, index) in degree.files" :key="'degree_file'+index">
                                            <div class="itme-disct-doc" v-for="(item, index) in file.file_items" :key="'degree_item'+index">
                                                <div class="itme-disct-left">
                                                    <a
                                                        href="#"
                                                        @click="downloadFile(item)"
                                                    >
                                                        <i
                                                            class="el-icon-download"
                                                        ></i>
                                                    </a>
                                                    <i class="el-icon-view"></i>
                                                </div>
                                                <div class="itme-disct-right">
                                                    <img
                                                        :src="appURL + item.path"
                                                    />
                                                </div>
                                                <div class="name-distc">
                                                    Fayil: <b>{{ item.original_name }}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Lavozim">
                                <div class="itme-infom">
                                    <h5 class="title">Buyuruq no'meri</h5>
                                    <p>{{ rank.command_number ? rank.command_number : ''}}</p>
                                </div>

                                <div class="itme-infom">
                                    <h5 class="title">
                                        Buyuruq imolagan shaxs FISH
                                    </h5>
                                    <p>{{rank.person_who_signed ? rank.person_who_signed : ''}}</p>
                                </div>

                                <div class="itme-infom">
                                    <h5 class="title">
                                        Buyuruq imzolagan shaxs Lavozmi
                                    </h5>
                                    <p>{{ rank.person_rank ? rank.person_rank : '' }}</p>
                                </div>

                                <div class="itme-infom">
                                    <h5 class="title">Buyuruq sanasi</h5>
                                    <p>{{ rank.command_date ? rank.command_date : '' }}</p>
                                </div>

                                <div class="itme-infom">
                                    <h5 class="title">
                                        Ilmiy Daraja qanday hujatlar bilan
                                        imzolangan seriyasi raqami
                                    </h5>
                                    <p>{{ rank.command_serial_number ? rank.command_serial_number : '' }}</p>
                                </div>

                                <el-divider content-position="left">
                                    Boshqa Tibbiy muassasalarda boshqaruvda
                                    ishlaganmi
                                </el-divider>
                                <div v-for="(rank_item, index) in rank.rank_items" :key="'rank_item-'+index">
                                    <div class="itme-infom">
                                        <h5 class="title">Boshqaruvda</h5>
                                        <p>{{ rank_item.in_management ? 'Ha' : (!rank_item.in_management ? 'Yo\'q' : '') }}</p>
                                    </div>

                                <div class="itme-infom">
                                    <h5 class="title">
                                        Qaysi Tashkilot ishlagan
                                    </h5>
                                    <p>{{rank_item.organization ? rank_item.organization : ''}}</p>
                                </div>

                                <div class="itme-infom">
                                    <h5 class="title">
                                        Qaysi lavozmida ishlgan
                                    </h5>
                                    <p>{{rank_item.rank ? rank_item.rank : ''}}</p>
                                </div>

                                <br />
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import _ from 'lodash';
export default {
    computed: {
        ...mapGetters({
            model: 'doctors/model',
            informations: 'doctors/informations',
            work_experiences: 'doctors/work_experiences',
            certificates: 'doctors/certificates',
            academic_degrees: 'doctors/academic_degrees',
            rank: 'doctors/rank'
        }),
    },
    data() {
        return {
            appURL: process.env.VUE_APP_URL_DOCS,
            sarcheBlocks: "",
            filtered_rank: {},
            loadingData: false
        };
    },
    created(){
        this.load();
    },
    methods: {
        ...mapActions({
            show: "doctors/show",
            download_file: "doctors/downloadFile"
        }),
        load(){
            let doctor_id = parseFloat(this.$route.params.id);
            if (!this.loadingData) {
                this.loadingData = true;
                this.show(doctor_id).then((res) => {
                    this.loadingData = false;
                }).catch((err) => {
                    this.loadingData = false;
                });
            }
        },
        async  downloadFile(file){
            let fileName = file.original_name;
            this.download_file({file_id: file.id}).then((res) => {
                const file_url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/*'}));
                const file_link = document.createElement('a');
                file_link.href = file_url;
                file_link.setAttribute('download', fileName);
                document.body.appendChild(file_link);
                file_link.click();
            }).catch((err) => {
                console.log(err.response.data);
            });
        },
    },
    
    beforeRouteLeave(to, from, next) {
        this.$store.commit("doctors/EMPTY_MODEL");
        next();
    },
};
</script>
